import { rightCombineWalkInScreen } from "../constants/AppConstants";
import * as ActionConstants from "../constants/ReduxConstants";

const initialState = {
  walkInCustomerList: [],
  activeRightScreen: rightCombineWalkInScreen.userListingScreen,
  userGroupData: [],
  selectedGroup: [],
  isSideDrawerOpen: false,
};

export const walkInCustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionConstants.GET_WALK_IN_CUSTOMER_LIST:
      return {
        ...state,
        walkInCustomerList: action.data,
      };

    case ActionConstants.WALK_IN_RIGHT_CHANGE_SCREEN:
      return {
        ...state,
        activeRightScreen: action.data,
      };

    case ActionConstants.SELECT_GROUP_ORDER_DATA:
      return {
        ...state,
        userGroupData: action.data,
      };

    case ActionConstants.SELECT_GROUP_DATA:
      return {
        ...state,
        selectedGroup: action.data,
      };

    case ActionConstants.OPEN_GROUP_SIDE_DRAWER:
      return {
        ...state,
        isSideDrawerOpen: action.data,
      };

    case ActionConstants.RESET_WALK_IN_CUSTOMER:
      return initialState;

    default:
      return state;
  }
};
