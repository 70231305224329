import React from "react";

const Loader = () => {
  return (
    <div>
      <span className="ant-spin-dot ant-spin-dot-spin">
        <i></i>
        <i></i>
        <i></i>
        <i></i>
      </span>
    </div>
  );
};

export default Loader;
